
























































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, Vue } from 'vue-property-decorator'
// import BMapGL from 'BMapGL'
import { CheckboxGroup, Checkbox, Radio } from 'element-ui'
import { getDicts, getDictName } from '@/utils/dict'
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Radio)

// 地图点位图标
const greenDefault = require('@/assets/images/map_green_default.png')
const greenChoosed = require('@/assets/images/map_green_choosed.png')
const orangeDefault = require('@/assets/images/map_orange_default.png')
const orangeChoosed = require('@/assets/images/map_orange_choosed.png')
// const icon = [greenDefault, greenChoosed, orangeDefault, orangeChoosed]

@Component
export default class Statistic extends Vue {
  // 字典数据
  // 设备类型
  deviceTypeDicts: any = []
  // 设备状态
  deviceStatusDicts: any = []
  deviceLists: any = []
  deviceStatus = ''
  tabs = [
    {
      name: '设备概览'
    },
    {
      name: '运行状态'
    }
  ]
  tabActive = -1
  deviceChecked = []

  async beforeCreate() {
    // 获取相关字典
    // 设备类型
    this.deviceTypeDicts = await getDicts('DEVICE_TYPE')
    // 设备状态
    this.deviceStatusDicts = await getDicts('DEVICE_STATUS')
    this.deviceStatusDicts.unshift({ value: '', name: '全部' })
    this.deviceStatusDicts.map((item: any) => {
      item.isChecked = false
    })
    this.deviceStatusDicts[0].isChecked = true
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  created() {
    this.getEquipmentList()
  }
  // 获取设备列表
  async getEquipmentList() {
    const p = this.deviceChecked.toString()
    this.$api.equipment
      .getEquipmentList({
        size: '-1',
        status: this.deviceStatus,
        deviceTypes: p
      })
      .then((res: any) => {
        const data = res.data
        this.deviceLists = data.data
        this.baiduMap()
      })
  }

  changeRadio(item: any) {
    this.deviceStatus = item
    this.getEquipmentList()
  }

  tabChange(index: number) {
    this.tabActive = index
  }

  handleCheckedDeviceChange() {
    this.getEquipmentList()
  }

  checkedChange(item: any) {
    item.isChecked = true
    this.deviceStatusDicts.map((v: any) => {
      v.isChecked = v.id === item.id ? true : false
    })
  }

  baiduMap() {
    const BMapGL = (window as any).BMapGL
    const map = new BMapGL.Map('equipMap')
    // 地图中心点
    const point = new BMapGL.Point(120.626999, 31.332664)
    map.centerAndZoom(point, 18)
    // map.addOverlay(new BMapGL.Marker(point))
    map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
    // 地图点坐标渲染
    this.deviceLists.map((v: any) => {
      const myIcon = new BMapGL.Icon(
        v.status === 1 ? greenDefault : orangeDefault,
        // icon[v.type],
        // greenDefault,
        new BMapGL.Size(34, 34)
      )
      const point = new BMapGL.Point(v.lng, v.lat)
      // 创建标注对象并添加到地图
      const marker = new BMapGL.Marker(point, { icon: myIcon })
      map.addOverlay(marker)
      marker.addEventListener('click', () => {
        const opts = {
          title: this.filterDictName(this.deviceTypeDicts, v.type)
        }
        const text =
          '<div>设备名称：' +
          v.deviceName +
          '</div>' +
          '<div>型号：' +
          v.model +
          '</div>' +
          '<div>状态：' +
          (v.status === 1 ? '启用' : '关闭') +
          '</div>'
        const infoWindow = new BMapGL.InfoWindow(text, opts)
        marker.setIcon(
          new BMapGL.Icon(
            // greenChoosed,
            v.status === 1 ? greenChoosed : orangeChoosed,
            new BMapGL.Size(34, 34)
          )
        )
        map.openInfoWindow(infoWindow, point)
      })
    })
  }
}
